const baseUrl = process.env.GO_API_BASE_URL;
export interface ApiConfig {
  checkIn: string;
  logout: string;
  resend: string;
  me: string;
  cookieConsent: string;
  chatToken: string;
  registerEvent: string;
  appointments: string;
  eventParticipation: string;
  defaultEventSource: string;
  chat: string;
}
const _apisURIs = {
  concierge: `${baseUrl}/api/v1`,
  events: `${baseUrl}/api/v2`,
  content: `${baseUrl}/api/content`,
  chatToken: `${baseUrl}/api/v1`,
};
export const apiConfig: ApiConfig = {
  checkIn: `${_apisURIs.concierge || ''}/users/check-in`,
  logout: `${_apisURIs.concierge || ''}/users/logout`,
  resend: `${_apisURIs.concierge || ''}/users/resend`,
  me: `${_apisURIs.concierge}/users/current`,
  cookieConsent: `${_apisURIs.concierge || ''}/users/processing`,
  chatToken: `${baseUrl}/api/v1`,
  registerEvent: `${_apisURIs.concierge}/events`,
  appointments: `${_apisURIs.concierge}/appointments`,
  eventParticipation: `${_apisURIs.concierge}/events/participation`,
  defaultEventSource: `${_apisURIs.events}/events.json`,
  chat: 'https://chat.cuenect.de',
};

export const apisURIs = _apisURIs;
