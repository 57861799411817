import { apiConfig } from '../config/api-config';
import { HttpService } from './http';

export class AdhocService {
  static sendRequest = async (tags: string[]) => {
    const url = `${apiConfig.appointments}/queue`;

    try {
      const result = await HttpService.post({
        url,
        data: { tags },
        config: {
          withCredentials: true,
        },
      });

      if (result) {
        return result.status === 204;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  static getStatus = async () => {
    const url = `${apiConfig.appointments}/queue`;
    try {
      const result = await HttpService.get({
        url,
        config: { withCredentials: true },
      });
      if (result) {
        return result.data;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  static deleteRequest = async () => {
    const url = `${apiConfig.appointments}/queue`;
    try {
      const result = await HttpService.delete({
        url,
        config: { withCredentials: true },
      });

      if (result) {
        return result.data;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  };
}
