/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@cue/atoms';
import { Modal, useModal } from '@cue/organisms';
import { useResendAccess } from '@project/hooks/use-resend-access';
import { useUser } from '@project/hooks/use-user';
import { useFlag } from '@project/providers/flags-provider';
import { AdhocNotification } from '@project/site/modules/adhoc-request/adhoc-notification';
import { PageProps } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { ResendAccesModal } from '../components/resend-acess-modal';
import { SiemensChatBot } from '../components/siemens-chat-bot';
import { CallToActionOverlay } from './call-to-action-overlay';
import { COOKIE_CONSENT_TYPE, ConsentTypes, CookieConsent } from './cookie-consent';
import { HIDE_RESEND_MODAL } from './hide-resend-modal';
import { QueryParamTrigger } from './query-param-trigger';
import { AnalyticsTransformer, LoadAnalytics } from '../hooks/analytics';
import { LocalStorageUtil } from '@cue/utility';

type ProjectSpecificPageWrapperProps = PageProps<
  Record<string, unknown>,
  Record<string, unknown>,
  unknown,
  object
> & {
  children?: React.ReactNode;
};

// Add Specific Pages Wrapper here

export const ProjectSpecificPageWrapper: React.FC<ProjectSpecificPageWrapperProps> = (props) => {
  const { user, loading: userLoading } = useUser();
  const autoOpenResend = useFlag('resend-access-auto');

  const { isOpen, setIsOpen: setResendAccessModalOpen } = useResendAccess();

  const [reauthModal] = useModal();

  const reauthGradient = useRef<HTMLDivElement>(null);
  const consent = LocalStorageUtil.get<ConsentTypes>(COOKIE_CONSENT_TYPE);
  const resendModalState = LocalStorageUtil.get<boolean>(HIDE_RESEND_MODAL);
  let authTimeOut: Timer | null = null;
  const GRADIENT_VISIBLE_CLASS = 'project-resend-access-gradient-visible';
  useEffect(() => {
    if(!props.path.includes("onsite-schedule")){
      if (authTimeOut) clearTimeout(authTimeOut);
      if (autoOpenResend && !resendModalState && consent && user === false) {
        authTimeOut = setTimeout(() => {
          reauthModal.current?.open();
          reauthGradient?.current?.classList.add(GRADIENT_VISIBLE_CLASS);
        }, 2000);
      }
    }
   
  }, [consent, authTimeOut, userLoading, user, reauthModal, resendModalState]);

  useEffect(() => {
    if(!props.path.includes("onsite-schedule")){ if (isOpen) {
      reauthModal.current?.open();
      reauthGradient?.current?.classList.add(GRADIENT_VISIBLE_CLASS);
    } else {
      reauthGradient?.current?.classList.remove(GRADIENT_VISIBLE_CLASS);
    }}
  }, [isOpen]);

  useEffect(() => {
    // @ts-ignore
   window.modalLogin = {
    open: () => {
      if(!props.path.includes("onsite-schedule")) reauthModal.current?.open();
    },
    close: () => {
      reauthModal.current?.close();
    }
   }
  }, [reauthModal])
  

  const includeSiemensChatBot =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props.pageContext?.globalOptions?.include_siemens_chat_bot === 'true' &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props.pageContext.pageInfo.includeSiemensChatBot;

  useEffect(() => {
    if (!userLoading && user) AnalyticsTransformer.setUserData();
  }, [userLoading, user]);

  const closeAuthModal = () => {
    if (reauthModal?.current?.animatedClose)
      reauthModal.current.animatedClose({ className: 'close', duration: 250 });
    LocalStorageUtil.set<boolean>(HIDE_RESEND_MODAL, true);
    setResendAccessModalOpen(false);
  };

  return (
    <QueryParamTrigger>
      <AdhocNotification />
      <Modal
        showDim={false}
        onClose={() => {
          reauthGradient?.current?.classList.remove(GRADIENT_VISIBLE_CLASS);
        }}
        placement={{ vertical: 'bottom' }}
        ref={reauthModal}
        id="reauth_model"
        dataTestId="cue-test-reauth-modal"
        overrideCSS={{
          '.cue-modal-content': {
            padding: '0',
            pointerEvents: 'all',
          },
          '.cue-modal-close-button-wrapper': {
            pointerEvents: 'all',
          },
          '.cue-modal-backdrop': {
            background: 'none',
            pointerEvents: 'none',
          },
        }}
        customCloseRenderer={() => (
          <Button
            icon="close"
            data-test-id="cue-test-modal-close"
            styling="modalClosePlain project-resend-access-close"
            onClick={() => {
              AnalyticsTransformer.trackEvent({
                category: 'register',
                type: 'modal',
                action: 'close',
              });
              closeAuthModal();
            }}
          />
        )}>
        <ResendAccesModal
          fromAdhoc={typeof isOpen === 'object' && isOpen?.isAdhoc}
          closeModal={() => closeAuthModal()}
        />
      </Modal>
      <div ref={reauthGradient} className={`project-resend-access-gradient`}></div>
      <CallToActionOverlay {...props} hidden={!consent || isOpen}>
        {props.children}
      </CallToActionOverlay>
      {!userLoading && (
        <LoadAnalytics
          whitelist={[
            'https://messe.fairtouch.siemens.com',
            'https://d2vdrxov3sh01c.cloudfront.net',
          ]}
        />
      )}
      {props.path !== '/de/showroom-fullscreen' && <CookieConsent custom_consent />}
      {includeSiemensChatBot ? <SiemensChatBot showOn="all-not-mobile" /> : null}
    </QueryParamTrigger>
  );
};
