import { useEffect } from 'react';
import { AdhocStateType, useAdhoc } from './use-adhoc';
import { Modal, useModal } from '@cue/organisms';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@cue/atoms';
import { Howl } from 'howler';

export const AdhocNotification = () => {
  const { status } = useAdhoc();
  const [modalRef] = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    if (status.status === AdhocStateType.CONFIRMED) {
      modalRef.current?.open();
      new Howl({
        src: ['/assets/sounds/queue.mp3'],
      }).play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const openMeeting = () => {
    if (status?.meetingURL) window.open(status?.meetingURL, '_blank');
  };
  if (status?.status !== AdhocStateType.CONFIRMED) return <></>;
  return (
    <Modal ref={modalRef} maxWidth={400}>
      <div className="adhoc-modal">
        <h3>{t('ad-hoc.success.headline')}</h3>
        <p>{t('ad-hoc.success.subline')}</p>
        <div className="adhoc-modal-icon">
          <div>
            <Icon name="check" />
          </div>
        </div>
        <Button onClick={() => openMeeting()}>{t('ad-hoc.success.to-meeting.cta')}</Button>
      </div>
    </Modal>
  );
};
