import { Auth, GeneralUserFields, LocalUserData } from '../../cue-api/auth';
import { AuthenticationContext } from '../../providers/auth-provider';
import { useToastNotification } from '@cue/organisms';
import { LocalStorageUtil, TimeUtil } from '@cue/utility';
import { useTheme } from '@emotion/react';
import React from 'react';
import { singletonHook } from 'react-singleton-hook';

export const _useUserImpl = () => {
  const authContext = React.useContext(AuthenticationContext);

  if (!authContext) {
    throw new Error('useUser can only be used within an AuthenticationProvider');
  }

  const {
    state: { user, isAuthenticated, loading },
    actions: { login, logout, refetch },
  } = authContext;

  const notification = useToastNotification();
  notification.addTheme(useTheme());

  const auth = React.useMemo(() => new Auth(), []);

  const getChatToken = async (room: string): Promise<string | null> =>
    await auth.getChatToken(room);

  const userTimezone = React.useMemo(() => {
    return TimeUtil.getUsersTimezone();
  }, []);

  const [userUpdateTrigger, setUserUpdateTrigger] = React.useState(0);

  /* eslint-disable react-hooks/exhaustive-deps */
  // userUpdateTrigger shall trigger update, even though it is not a direct depenency
  const userPreferredTimezone: string | null = React.useMemo(() => {
    return LocalStorageUtil.get('user-preferred-timezone');
  }, [userUpdateTrigger]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const setPreferredTimezone = (timezone: string) => {
    LocalStorageUtil.set('user-preferred-timezone', timezone);
    setUserUpdateTrigger(userUpdateTrigger + 1);
  };

  const updateFields = async (payload: GeneralUserFields) => {
    if (user) {
      const updateValues = { ...(user?.fields || {}), ...payload };
      await auth.updateFields(updateValues);
      refetch();
    }
  };

  return {
    user,
    preferredTimezone: userPreferredTimezone,
    setPreferredTimezone,
    isAuthenticated,
    login,
    logout,
    getChatToken,
    updateFields,
    loading,
    userTimezone,
  };
};

/* eslint-disable @typescript-eslint/no-empty-function */
const defaultState = {
  user: undefined,
  preferredTimezone: null,
  setPreferredTimezone: (tz: string) => console.log(tz),
  isAuthenticated: undefined,
  login: () => {},
  logout: () => {},
  updateFields: () => {},
  getChatToken: async () => null,
  loading: false,
  userTimezone: TimeUtil.getUsersTimezone(),
};
/* eeslint-enable */

export const useUser = singletonHook<useUserReturn>(defaultState, _useUserImpl, {
  unmountIfNoConsumers: false,
});

export interface useUserReturn {
  login(token: string): void;
  logout(): void;
  getChatToken(room: string): Promise<string | null>;
  updateFields(payload: GeneralUserFields): void;
  user: false | LocalUserData | undefined;
  isAuthenticated: boolean | undefined;
  loading: undefined | boolean;
  userTimezone: string | null;
  preferredTimezone: string | null;
  setPreferredTimezone: (timezone: string) => void;
}
