// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LocalStorageUtil } from '@cue/utility';
import { COOKIE_CONSENT_TYPE, ConsentTypes } from '@project/specific/page-wrapper/cookie-consent';

const USE_UC = true;
class UC_UI_Debug {
  static showSecondLayer() {
    alert('SHOW');
  }

  static areAllConsentsAccepted() {
    return true;
  }
  static isInitialized() {
    return true;
  }
}

if (typeof UC_UI === 'undefined') {
  // If not, create it and assign UC_UI_Debug to it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const UC_UI = UC_UI_Debug;
}
export class Consent {
  constructor() {
    this.interval = 10; // start with 10ms
    this.checkCount = 0;
    this.maxChecks = 10; // increase interval after 10 checks
  }

  static async waitForUcUiToBeLoaded() {
    return new Promise((resolve, reject) => {
      const checkInterval = setInterval(() => {
        if (window.UC_UI) {
          console.log('UC_UI is now available');
          clearInterval(checkInterval);
          resolve(window.UC_UI);
        } else {
          // console.log('UC_UI is not yet available, retrying...');
          this.checkCount++;
          if (this.checkCount >= this.maxChecks) {
            this.interval *= 2; // double the interval
            this.checkCount = 0; // reset the check count
          }
        }
      }, this.interval);

      // Stop checking after 10 seconds
      setTimeout(() => {
        clearInterval(checkInterval);
        reject(new Error('UC_UI not found within 10 seconds'));
      }, 10000);
    });
  }
  static async areAllConsentsAccepted(): boolean {
    try {
      const UC_UI = USE_UC ? await this.waitForUcUiToBeLoaded() : null;
      return Boolean(
        USE_UC
          ? UC_UI?.areAllConsentsAccepted()
          : LocalStorageUtil.get<ConsentTypes>(COOKIE_CONSENT_TYPE) === ConsentTypes.ALL,
      );
    } catch (error) {
      console.log('Consent', error);
      return false;
    }
  }

  static async openPreferences(): void {
    try {
      const UC_UI = USE_UC ? await this.waitForUcUiToBeLoaded() : null;
      UC_UI.showSecondLayer();
    } catch (error) {
      console.error('NO UC_UI available');
    }
  }
}
