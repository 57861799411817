import { iconMapping } from './icon-mapping';
import iconsSprite from './icons/sprite.svg';
import _cq, { cqTypes } from './scss/cq/cq';
import _mq, { mqTypes } from './scss/mq/mq';
import './scss/style.scss';

const containerQueries = {};
export const cq = <T extends keyof cqTypes>(size: T): string => {
  return _cq[size] ? `@container (min-width: ${_cq[size]}px)` : '';
};

for (const key in _cq) {
  const value = _cq[key as keyof cqTypes];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (typeof value === 'string') containerQueries[key] = cq(key);
}
const mediaQueries = {};
export const mq = <T extends keyof mqTypes>(size: T): string => {
  return _mq[size] ? `@media (min-width: ${_mq[size]}px)` : '';
};
for (const key in _mq) {
  const value = _mq[key as keyof mqTypes];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (typeof value === 'string') mediaQueries[key] = mq(key);
}

export default {
  iconSpriteSheet: iconsSprite,
  iconMapping,
  mediaQueries,
  containerQueries,
};
