export const iconMapping: Record<string, CueIconName> = {
  default: 'arrowhead-right',
  clock: 'clock',
  close: 'close',
  tagSelected: 'close-small',
  check: 'check',
  arrow: 'arrowhead-right',
  arrowRight: 'arrowhead-right',
  arrowDown: 'arrowhead-down',
  arrowRightSecondary: 'arrow-right',
  arrowLeft: 'arrowhead-left',
  buildings: 'buildings',
  download: 'download',
  home: 'home',
  user: 'user',
  back: 'back',
  filter: 'filter2',
  videostoryClose: 'arrow-double-right',
  indicatorActive: 'check',
  language: 'language',
  play: 'play',
  plus: 'plus',
  minus: 'minus',
  pause: 'pause',
  soundOn: 'volume-loud',
  soundOff: 'volume-mute',
  fullscreenOn: 'full-screen-exit',
  fullscreenOff: 'full-screen',
  ics: 'calendar',
  mailto: 'email',
  share: 'share',
  favourite: 'bookmark',
  favouriteMarked: 'bookmarkMarked',
  search: 'search',
  virtual: 'virtual',
  twitter: 'twitter',
  linkedin: 'linked-in',
  facebook: 'facebook',
  youtube: 'youtube',
  instagram: 'instagram',
  star: 'star',
  starFilled: 'star-filled',
  login: 'login',
  menu: 'menu',
  radio: 'connected-radio-waves',
  chat1: 'chat-1',
  monitor: 'monitor',
  edit: 'edit',
  live: 'live',
  dropdown: 'dropdown',
  warning: 'warning',
  undo: 'undo',
  link_extern: 'link-extern',
  information: 'information',
  documentPdf: 'document-pdf',
  app: 'apps',
  info: 'information',
  location: 'location',
  navigation: 'navigation-left',
  showroomFilter: 'filter',
};
