import { Avatar, Heading, Paragraph, Tooltip } from '@cue/atoms';
import { Image } from '@project/cms/modules';
import React from 'react';

export const AdhocExperts: React.FC<AdhocExpertsProps> = ({ id, name, occupation, image }) => {
  return (
    <>
      <div data-tooltip-id={id}>
        <Avatar
          speaker={{
            /* name: name,
          occupation: occupation, */
            image: image.src,
          }}></Avatar>
      </div>
      <Tooltip place="top" variant="light" id={id}>
        <Heading type="h5" className="adhoc-expert-tooltip-name">
          {name}
        </Heading>
        {occupation ? (
          <Paragraph className="adhoc-expert-tooltip-occ">{occupation}</Paragraph>
        ) : null}
      </Tooltip>
    </>
  );
};

export type AdhocExpertsProps = {
  id: string;
  name: string;
  occupation: string;
  image: Image;
};

{
  /* <div
            data-tooltip-id={expert.id}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}>
            <Avatar
              speaker={{
              name: expert.name,
              occupation: expert.occupation, 
                image: expert.image.src,
              }}></Avatar>
          </div> */
}
{
  /* <Tooltip
                place="bottom"
                variant="light"
                id={id}
                className={`cue-agenda-event-speaker-tooltip ${showTooltip ? '' : 'hide'}`}
                isOpen={showTooltip}>
                <Heading type="h5" className="cue-agenda-event-speaker-tooltip-name">
                  {speaker.name}
                </Heading>
                {speaker.occupation ? (
                  <Paragraph className="cue-agenda-event-speaker-tooltip-occ">
                    {speaker.occupation} {speaker.company ? ` (${speaker.company})` : ''}
                  </Paragraph>
                ) : null}
              </Tooltip> */
}
