import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const httpClient = axios.create();
httpClient.defaults.timeout = 25000;

export class HttpService {
  public static async post<T = object>({
    url,
    data,
    config,
  }: {
    url: string;
    data?: object;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> {
    return await httpClient.post<T>(url, data, config);
  }

  public static async get<T = object>({
    url,
    config,
  }: {
    url: string;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> {
    return await httpClient.get<T>(url, config);
  }

  public static async delete<T = object>({
    url,
    config,
  }: {
    url: string;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> {
    return await httpClient.delete<T>(url, config);
  }
}
