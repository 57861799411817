import { useQueryParams } from '@cue/hooks';
import { useToastNotification } from '@cue/organisms';
import { LocalStorageUtil } from '@cue/utility';
import { Auth, LocalUserData } from '@project/cue-api/auth';
import { HIDE_RESEND_MODAL } from '@project/specific/page-wrapper/hide-resend-modal';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

const defaultState: AuthState = {
  user: undefined,
  isAuthenticated: undefined,
  error: null,
  loading: true,
};

export interface AuthState {
  user: LocalUserData | false | undefined;
  isAuthenticated: boolean | undefined;
  error?: unknown;
  loading?: boolean;
}

type AuthActions = {
  login: (token: string) => Promise<void> | void;
  logout: () => Promise<void> | void;
  refetch: () => Promise<void> | void;
};

/*  eslint-disable  @typescript-eslint/no-empty-function */
const defaultActions: AuthActions = {
  login: () => {},
  logout: () => {},
  refetch: () => {},
};
/*  eslint-enable  @typescript-eslint/no-empty-function */

export const AuthenticationContext = React.createContext({
  state: defaultState,
  actions: defaultActions,
});

interface AuthenticationContextProviderProps {
  children?: React.ReactNode;
}

export const AuthenticationProvider: React.FC<AuthenticationContextProviderProps> = (props) => {
  const [queryParams, setQueryParams] = useQueryParams();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | undefined>(undefined);

  // auto signin
  const {
    data: user,
    isLoading: autoLoginIsLoading,
    error: autoLoginError,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ['user'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: async () => {
      const urlToken = new URLSearchParams(
        typeof window !== 'undefined' ? window.location.search : '',
      ).get('token');

      if (urlToken) {
        const user = await authenticateUser(urlToken);
        if (!user) {
          setIsAuthenticated(false);
          throw new Error('URL-Token is not valid');
        }
        setIsAuthenticated(true);
        return user;
      }

      const localUserToken = LocalStorageUtil.get('user-token');
      if (localUserToken) {
        const user = await authenticateUser(String(localUserToken));
        if (!user) {
          setIsAuthenticated(false);
          throw new Error('Local Token is not valid');
        }
        setIsAuthenticated(true);
        return user;
      }
      setIsAuthenticated(false);
      return false;
    },
  });

  const resetUser = React.useCallback(() => {
    if ('token' in queryParams) {
      /* eslint-disable */
      const { token, ...rest } = queryParams;
      /* eslint-enable */
      setQueryParams(rest);
    }
    LocalStorageUtil.remove('user-token');
    refetchUser();
  }, [queryParams, setQueryParams, refetchUser]);

  // manual sign in
  const {
    mutate: loginMutation,
    isLoading: manualLoginIsLoading,
    error: manualLoginError,
  } = useMutation({
    mutationFn: async (token: string) => {
      const user = await authenticateUser(token);
      if (!user) {
        throw new Error('URL-Token is not valid');
      }
      return user;
    },
    onSuccess: (data) => {
      refetchUser();
      return data;
    },
    mutationKey: ['login'],
  });

  // signout
  const {
    mutate: signOutMutation,
    isLoading: signoutIsLoading,
    error: signoutError,
  } = useMutation({
    mutationFn: async () => {
      const signOutResult = await auth.signOut();
      if (!signOutResult) {
        throw new Error('Could not sign out');
      }
      resetUser();
    },
    onSuccess: (data) => {
      refetchUser();
      return data;
    },
    mutationKey: ['logout'],
  });

  async function authenticateUser(token: string) {
    const user = await auth.authenticate(token);
    if (user) {
      LocalStorageUtil.set('user-token', token);
      return user;
    }
    LocalStorageUtil.remove('user-token');
    return false;
  }

  const { t } = useTranslation();
  const notification = useToastNotification();
  const auth = React.useMemo(() => new Auth(), []);

  const actions: AuthActions = { login, logout, refetch };

  React.useEffect(() => {
    if (autoLoginError || manualLoginError) {
      console.error(autoLoginError || manualLoginError);
      notification.error({
        title: t('authentication.error.title'),
        message: t('authentication.error.message'),
        duration: 5,
      });

      resetUser();
      console.error(autoLoginError || manualLoginError);

      // RESET HIDE LOGIN FORM -
      LocalStorageUtil.set<boolean>(HIDE_RESEND_MODAL, false);
    }
  }, [autoLoginError, manualLoginError, t, notification, resetUser]);

  React.useEffect(() => {
    if (signoutError) {
      notification.error({
        title: t('logout.error.title'),
        message: t('logout.error.message'),
        duration: 5,
      });
      resetUser();
      console.error(signoutError);
    }
  }, [signoutError, t, notification, resetUser]);

  async function login(token: string) {
    loginMutation(token);
  }

  async function refetch() {
    refetchUser();
  }

  async function logout() {
    signOutMutation();
  }

  const state: AuthState = {
    user: user,
    isAuthenticated: isAuthenticated,
    error: autoLoginError || manualLoginError || signoutError,
    loading: autoLoginIsLoading || manualLoginIsLoading || signoutIsLoading,
  };

  return (
    <AuthenticationContext.Provider value={{ state, actions }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};
