import { apiConfig } from '../config/api-config';
import { useUser } from './use-user';
import React from 'react';

type ConsentInfo = {
  consent: boolean;
};

export const useCookieConsent = () => {
  const { isAuthenticated } = useUser();
  const [consentState, setConsentState] = React.useState<ConsentInfo | undefined>(undefined);
  const consentEndpoint = apiConfig.cookieConsent;

  React.useEffect(() => {
    if (!isAuthenticated) return;
    getConsent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  async function getConsent() {
    try {
      const response = await fetch(consentEndpoint);
      const data = await response.json();
      setConsentState(data as ConsentInfo);
    } catch (e) {
      console.log('getConsent ERROR:', e);
    }
  }

  async function setConsent(consent: ConsentInfo) {
    try {
      const response = await fetch(consentEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(consent),
      });
      const data = await response.json();
      setConsentState(data as ConsentInfo);
    } catch (e) {
      console.log('setConsent ERROR:', e);
    }
  }

  return [consentState, setConsent];
};
