import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const defaultState: useResendAccessReturn = {
  setIsOpen: () => null,
  isOpen: false,
  loaded: false,
};
export type useResendAccessProps = {
  isAdhoc: boolean;
};

export const _useResendAccessImpl = () => {
  const [isOpen, setIsOpen] = useState<false | useResendAccessProps>(false);

  const _setIsOpen = (open: false | useResendAccessProps) => {
    setIsOpen(open);
  };

  return {
    setIsOpen: _setIsOpen,
    isOpen,
    loaded: true,
  };
};

export const useResendAccess = singletonHook<useResendAccessReturn>(
  defaultState,
  _useResendAccessImpl,
  {
    unmountIfNoConsumers: false,
  },
);
export interface useResendAccessReturn {
  setIsOpen: (open: false | useResendAccessProps) => void;
  isOpen: boolean | useResendAccessProps;
  loaded?: boolean;
}
