import { ApiConfig, apiConfig } from '../../config/api-config';
import { HttpService } from '@cue/api';
import { LocalStorageUtil } from '@cue/utility';
import { AxiosResponse } from 'axios';

export class Auth {
  apiConfig: ApiConfig;
  constructor() {
    this.apiConfig = apiConfig;
  }

  public async authenticate(token?: string): Promise<LocalUserData | false> {
    if (!token) {
      return false;
    }

    const response = await this.checkIn(token);
    if (!response) {
      await this.logout();
      return false;
    }

    const {
      status,
      data: {
        analytics: { userId, customDimensions },
        person,
      },
    } = response;

    if (status !== 200) {
      return false;
    }

    LocalStorageUtil.set('user', {
      token,
      userId,
      customDimensions: customDimensions[0],
      person,
    });
    return { ...person, userId };
  }

  public async signOut(): Promise<boolean> {
    const result = await this.logout();
    if (!result) return false;
    return true;
  }

  public async getChatToken(room: string): Promise<string | null> {
    try {
      const result = this.chatToken(room);
      if (!result) return null;
      return result;
    } catch (e) {
      return null;
    }
  }

  public async updateFields(payload: GeneralUserFields): Promise<string | null> {
    const token = await HttpService.post({
      url: `${this.apiConfig.me}`,
      config: {
        withCredentials: true,
      },
      data: {
        person: {
          fields: {
            ...payload,
          },
        },
      },
    });

    if (!token) {
      throw new Error();
    }

    return token.data.token;
    /*  const result = this.chatToken(payload);
    if (!result) return null;

    return result; */
  }

  private async checkIn(token: string) {
    const result: AxiosResponse | false = await HttpService.post({
      url: this.apiConfig.checkIn,
      data: { token },
      config: { withCredentials: true },
    }).catch(() => false);

    return result;
  }

  private async logout() {
    const result: AxiosResponse | false = await HttpService.post({
      url: this.apiConfig.logout,
      config: { withCredentials: true },
    }).catch(() => false);

    LocalStorageUtil.remove('user');
    return result;
  }

  public async resendToken(email: string) {
    const result = await HttpService.post({
      url: this.apiConfig.resend,
      data: { email },
      config: {
        withCredentials: true,
      },
    });

    if (!result) {
      throw new Error();
    }

    return result;
  }
  /* private async me(): Promise<UserData> {
    const result: AxiosResponse<LocalUserData> = await HttpService.get({
      url: this.apiConfig.me,
      config: { withCredentials: true },
    });

    if (!result) {
      throw new Error();
    }

    return result.data.person;
  } */

  private async chatToken(room?: string) {
    try {
      const token = await HttpService.post({
        url: `${this.apiConfig.chatToken}/chats/${room}/token`,
        config: {
          withCredentials: true,
        },
      });

      if (!token) {
        throw new Error();
      }

      return token.data.token;
    } catch (e) {
      console.error(e);
    }
  }
}

export interface AuthProps {
  apiConfig: ApiConfig;
}

export type GeneralUserFields = {
  [key: string]: string;
  country: string;
  company: string;
  interests: string;
};

export interface IPersonData {
  firstName: string;
  lastName: string;
}

export interface LocalUserData extends UserData {
  token?: string;
  userId?: string;
}

export interface UserData extends Partial<IPersonData> {
  salutation?: {
    de: string;
    en: string;
  };

  address?: {
    de: string;
    en: string;
  };

  fields: GeneralUserFields; // TODO & ProjectSpecificUserFields;
  language: string;
}
