// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Heading, Text } from '@cue/atoms';
import { useQueryParams } from '@cue/hooks';
import { Modal, useModal } from '@cue/organisms';
import { StyledComponent } from '@cue/theme';
import { LocalStorageUtil } from '@cue/utility';
import { CSSObject } from '@emotion/styled';
import { useCookieConsent } from '@project/hooks/use-cookie-consent';
import { useUser } from '@project/hooks/use-user';
import { Consent } from '@project/site/core/consent/consent';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const COOKIE_CONSENT_TYPE = 'cookieConsent';
export enum ConsentTypes {
  ALL = 'all',
  REQUIRED = 'required',
}

class UC_UI_Debug {
  static showSecondLayer() {
    alert('SHOW');
  }

  static areAllConsentsAccepted() {
    return true;
  }
  static isInitialized() {
    return true;
  }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (typeof UC_UI === 'undefined') {
  // If not, create it and assign UC_UI_Debug to it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const UC_UI = UC_UI_Debug;
}
export const CookieConsent: React.FC<
  StyledComponent<CookieConsentCSSOverride> & CookieConsentProps
> = (props: CookieConsentProps) => {
  const [show, setShow] = useState(false);
  const [cookieConsentRef] = useModal();
  const { t } = useTranslation();
  const location = useLocation();
  const [params, setParams] = useQueryParams({ navigate, location });
  const { custom_consent } = props;
  const [, /* consentInGo */ setConsentInGo] = useCookieConsent();
  const { isAuthenticated } = useUser();

  useEffect(() => {
    // do not check unless we know auth status (it is needed to set the consent in go)
    if (isAuthenticated === undefined) return;
    if (custom_consent) {
      checkCustomConsent();
    } else {
      checkConsent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom_consent, isAuthenticated]);

  useEffect(() => {
    if (show) {
      cookieConsentRef.current?.open();
    } else {
      cookieConsentRef.current?.close();
    }
  }, [show, cookieConsentRef]);

  React.useEffect(() => {
    if (params[COOKIE_CONSENT_TYPE] === 'open') {
      if (custom_consent) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        UC_UI.showSecondLayer();
      } else {
        cookieConsentRef.current?.open();
      }
    } /*  else {
      cookieConsentRef.current?.close();
    } */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, cookieConsentRef]);

  const setConsentInLocalStorage = (type: ConsentTypes, reload = true) => {
    LocalStorageUtil.set<ConsentTypes>(COOKIE_CONSENT_TYPE, type);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { [COOKIE_CONSENT_TYPE]: cookieConsent, ...restOfParams } = params;
    /* eslint-enable @typescript-eslint/no-unused-vars */
    setParams({ ...restOfParams });
    setShow(false);
    checkConsent();
    if (reload) {
      setTimeout(() => {
        window.location.reload();
      }, 250);
    }
  };

  function handleConsentChange(e) {
    const relevantEvents = ['ACCEPT_ALL', 'DENY_ALL', 'SAVE'];
    if (!relevantEvents.includes(e.detail.type)) {
      return;
    }

    const allAccepted = UC_UI.areAllConsentsAccepted();
    const consentType = allAccepted ? ConsentTypes.ALL : ConsentTypes.REQUIRED;
    const lsCookieSetting = LocalStorageUtil.get<ConsentTypes>(COOKIE_CONSENT_TYPE);

    if (isAuthenticated) {
      setConsentInGo({ consent: consentType === ConsentTypes.ALL });
    }

    if (!isAuthenticated) {
      // only first setting for unauthenticated users
      if (lsCookieSetting === null) {
        setConsentInGo({ consent: consentType === ConsentTypes.ALL });
      } else {
        // console.log('ignoring update unauthenticated user');
      }
    }

    setConsentInLocalStorage(allAccepted ? ConsentTypes.ALL : ConsentTypes.REQUIRED, false);
  }

  function removeUsercentricsListener() {
    window.removeEventListener('UC_UI_CMP_EVENT', handleConsentChange);
  }

  async function setupUsercentricsListener() {
    try {
      await Consent.waitForUcUiToBeLoaded();
    } catch (error) {
      console.warn('COULD NOT INITIALIZE UC UI', error);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const initialized = UC_UI.isInitialized();
    if (!initialized) return;

    window.addEventListener('UC_UI_CMP_EVENT', handleConsentChange);
  }

  React.useEffect(() => {
    // do not setup listener unless we know auth status (it is needed to set the consent in go)
    if (isAuthenticated === undefined) return;
    setupUsercentricsListener();

    return () => removeUsercentricsListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const checkCustomConsent = React.useCallback(async () => {
    try {
      await Consent.waitForUcUiToBeLoaded();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const initialized = UC_UI.isInitialized();

      if (!initialized) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const allAccepted = UC_UI.areAllConsentsAccepted();
      // eslint-disable-next-line cuenect-web-rules/no-direct-use-of-local-storage
      const userHasMadeAChoice = localStorage.getItem('uc_user_interaction') === 'true';
      // do not set local storage before user has made a choice
      if (userHasMadeAChoice) {
        setConsentInLocalStorage(allAccepted ? ConsentTypes.ALL : ConsentTypes.REQUIRED, false);
        if (isAuthenticated) {
          setConsentInGo({ consent: allAccepted });
        }
      }
    } catch (error) {
      console.warn('COULD NOT INITIALIZE UC UI', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const checkConsent = () => {
    const consent = LocalStorageUtil.get<ConsentTypes>(COOKIE_CONSENT_TYPE);
    if (!consent) {
      setShow(true);
    } else if (consent === ConsentTypes.ALL) {
      try {
        window._paq.push(['setConsentGiven']);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Modal maxWidth={1000} ref={cookieConsentRef} hideCloseButton={true} id="cookie-consent">
      <div className="cue-project-cookie-consent">
        <div>
          <Heading type="h5">{t('cookies.headline')}</Heading>
          <Text>{t('cookies.copy')}</Text> <Text>{parse(t('cookies.copy-bottom'))}</Text>
        </div>
        <div className="cue-project-cookie-consent-buttons">
          <Button className="consent-required" onClick={() => setConsent(ConsentTypes.REQUIRED)}>
            {t('cookies.cta-required')}
          </Button>
          <Button className="consent-all" onClick={() => setConsent(ConsentTypes.ALL)}>
            {t('cookies.cta-full')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export type CookieConsentCSSOverride = {
  '.cue-project-cookie-consent'?: CSSObject;
  '.cue-project-cookie-consent-buttons'?: CSSObject;
};
export type CookieConsentProps = {
  custom_consent?: boolean;
};
